<template>
    <a-modal
        width="75%"
        v-model:open="isOpen"
        title=""
        @ok="clickOK"
        @cancel="clickCancel"
    >
        <a-descriptions
            title="슬라이드 이미지 설정"
            layout="horizontal"
            size="small"
            :bordered="true"
            :column="1"
        >
            <a-descriptions-item label="이미지 추가">
                <a-descriptions
                    layout="vertical"
                    size="small"
                    :column="1"
                    :bordered="true"
                >
                    <a-descriptions-item label="이미지">
                        <content-image-input
                            :value="currentImage"
                            name="resourceUrl"
                            path="/event-image-slider"
                            style="display: flex"
                            @update:value="uploadImage"
                        ></content-image-input>
                    </a-descriptions-item>
                    <a-descriptions-item label="액션 타입">
                        <enum-select
                            v-model:value="value.actionType"
                            typeName="event-action-type"
                        ></enum-select>
                    </a-descriptions-item>
                    <a-descriptions-item label="액션 파라미터">
                        <a-textarea v-model:value="value.actionParameter" />
                    </a-descriptions-item>
                    <a-descriptions-item>
                        <a-button @click="addImage">이미지 추가</a-button>
                    </a-descriptions-item>
                </a-descriptions>
            </a-descriptions-item>
            <a-descriptions-item label="이미지 리스트">
                <draggable
                    :list="value.slideImages"
                    item-key="value"
                    class="content-container"
                >
                    <template #item="{ element, index }">
                        <a-col :span="7" class="content-box">
                            <a-button
                                type="primary"
                                danger
                                class="btn-remove-content"
                                @click="remove(index)"
                            >
                                삭제
                            </a-button>
                            <img
                                alt="slide-thumbnail"
                                class="slide-thumbnail"
                                :src="element.imageUrl"
                            />
                            <a-row class="description-box">
                                <a-col :span="24" class="desc-title">
                                    ACTION TYPE
                                </a-col>
                                <a-col :span="24" class="desc-value">
                                    {{ element.actionType }}
                                </a-col>
                            </a-row>
                            <a-row class="description-box">
                                <a-col :span="24" class="desc-title">
                                    ACTION PARAMETER
                                </a-col>
                                <a-col :span="24" class="desc-value">
                                    {{ element.actionParameter }}
                                </a-col>
                            </a-row>
                        </a-col>
                    </template>
                </draggable>
            </a-descriptions-item>
        </a-descriptions>
    </a-modal>
</template>

<style scoped>
.content-container {
    display: flex;
    flex-wrap: wrap;
}
.content-box {
    width: 50%;
    display: flex;
    flex-direction: column;
    border: 1px solid #d2d2d2;
    border-radius: 12px;
    padding: 10px;
    overflow: hidden;
    margin-right: 10px;
}
.slide-thumbnail {
    width: 256px;
    height: 256px;
    margin-bottom: 10px;
}
.description-box {
    padding-bottom: 10px;
}
.desc-title {
    padding: 5px 10px;
    background-color: #8c8c8c;
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
}
.desc-value {
    font-size: 10px;
    font-weight: 500;
    padding: 5px 10px;
}
.btn-remove-content {
    margin-bottom: 10px;
}
</style>

<script lang="ts">
import { defineComponent, ref, toRef } from 'vue'
import ContentImageInput from '@/components/input/ContentImageInput.vue'
import EnumSelect from '@/components/input/EnumSelect.vue'
import Draggable from 'vuedraggable'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    name: 'EventSlideImageModal',
    components: { EnumSelect, ContentImageInput, Draggable },
    props: {
        valueProps: {
            type: Object,
        },
        isOpenProps: {
            type: Boolean,
        },
        propertyProps: {
            type: Array,
        },
    },

    setup(props: any) {
        const currentImage = ref(null)
        const value = toRef(props, 'valueProps')
        const isOpen = toRef(props, 'isOpenProps')
        return {
            value,
            currentImage,
            isOpen: isOpen,
        }
    },

    emits: ['modal:ok', 'modal:cancel', 'modal:addImages', 'modal:removeImage'],

    methods: {
        clickOK() {
            this.$emit('modal:ok')
        },
        clickCancel() {
            this.$emit('modal:cancel')
        },
        addImage() {
            const payload = {
                actionType: this.value.actionType,
                actionParameter: this.value.actionParameter,
                imageUrl: this.currentImage,
            }
            this.$emit('modal:addImages', payload)
            this.currentImage = null
            this.value.actionType = null
            this.value.actionParameter = null
            this.value.imageUrl = null
        },
        uploadImage(value: any) {
            this.currentImage = value
        },
        remove(index: any) {
            this.$emit('modal:removeImage', index)
        },
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums(['event-action-type'])
    },
})
</script>
