import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import ResourceTable from '@/components/ResourceTable.vue'
import { onMounted, ref } from 'vue'
import {
    formatBoolean,
    formatEnum,
    formatLocalDateTime,
} from '@/util/formmater'
import { required } from '@/util/input-validation'
import { InputComponents } from '@/components/InputMapper.vue'
import router from '@/router'
import { adminApi } from '@/fetchTemplate'
import { message } from 'ant-design-vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'CgmDevice',
  props: {
    userId: {
        type: String,
        required: true,
    },
},
  setup(__props) {

const props = __props

const filterSpec = ref({
    userId: {
        label: '사용자 ID',
        value: props.userId,
    },
})

const deviceRules = {
    startAt: [required],
    endAt: [required],
    deviceType: [required],
}

const deviceSpec = ref({
    startAt: {
        label: '시작일시',
        value: null,
        component: InputComponents.ADatetimePicker,
    },
    endAt: {
        label: '종료일시',
        value: null,
        component: InputComponents.ADatetimePicker,
    },
    deviceType: {
        label: '센서 타입',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'cgm-device-type',
    },
})

const deviceColumn = [
    {
        title: 'ID',
        dataIndex: 'tsid',
        width: 120,
        fixed: 'left',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
        },
        sorter: true,
    },
    {
        title: '센서 타입',
        dataIndex: 'deviceType',
        customRender: formatEnum('cgm-device-type'),
        width: 50,
    },
    {
        title: '자동 등록',
        dataIndex: 'autoRegister',
        customRender: formatBoolean,
        width: 60,
    },
    {
        title: '무료 체험',
        dataIndex: 'freeTrial',
        customRender: formatBoolean,
        width: 60,
    },
    {
        title: '센서 상태',
        dataIndex: 'sensorStatus',
        width: 60,
    },
    {
        title: '시작일시',
        dataIndex: 'startAt',
        customRender: formatLocalDateTime,
        width: 100,
    },
    {
        title: '종료일시',
        dataIndex: 'endAt',
        customRender: formatLocalDateTime,
        width: 100,
    },
]

const visible = ref(true)

const toggleVisible = () => {
    visible.value = !visible.value
    router.back()
}

const loading = ref(false)
const forceRegisterVcgm = async (search: () => void) => {
    loading.value = true
    try {
        await adminApi(`/api/cgm/force-register-vcgm/${props.userId}`, {
            method: 'POST',
        })
        await search()
    } catch (e) {
        console.error(e)
        message.error('VCGM 등록에 실패했습니다.')
    } finally {
        loading.value = false
    }
}

const vcgmEnable = ref(false)
const fetchVcgmStatus = async () => {
    const response = await adminApi(`/api/cgm/vcgm-status/${props.userId}`)
    vcgmEnable.value = !response.result
}

onMounted(() => {
    fetchVcgmStatus()
})

return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createBlock(_component_a_drawer, {
    visible: true,
    onClose: toggleVisible,
    width: "900px"
  }, {
    default: _withCtx(() => [
      _createVNode(ResourceTable, {
        ref: "cgmResourceTable",
        "resource-name": "cgmDevice",
        title: "CGM 센서",
        uri: "/api/ai-coaching-schedule/cgm-device",
        columns: deviceColumn,
        "filter-spec": filterSpec.value,
        "update-spec": deviceSpec.value,
        "update-rules": deviceRules,
        deletable: "",
        "load-on-mount": "",
        "primary-key": "tsid"
      }, {
        actions: _withCtx(({ search }) => [
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: ($event: any) => (forceRegisterVcgm(search)),
            loading: loading.value,
            disabled: vcgmEnable.value
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" VCGM 강제 등록 ")
            ])),
            _: 2
          }, 1032, ["onClick", "loading", "disabled"])
        ]),
        _: 1
      }, 8, ["filter-spec", "update-spec"])
    ]),
    _: 1
  }))
}
}

})