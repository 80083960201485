import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "",
    class: "benefit-screen-table",
    uri: "/api/advertisements/benefit",
    columns: _ctx.columns,
    scroll: { x: 'max-content', y: 1400 },
    "row-selection": {
            selectedRowKeys: _ctx.selectedRowKeys,
            onChange: _ctx.onSelectChange,
        },
    "resource-name": "benefitScreenTable",
    "load-on-mount": ""
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_a_button, {
        onClick: _cache[0] || (_cache[0] = () => _ctx.add())
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("광고 추가")
        ])),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["columns", "row-selection"]))
}