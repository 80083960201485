import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import ImageMultipleUploader from '@/components/input/ImageMultipleUploader.vue'
import { toRef } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'EventContentPropertiesInput',
  props: {
    value: {
        type: Object,
        default: () => {},
    },
    menu: {
        type: String,
        default: () => null,
    },
    subMenu: {
        type: String,
        default: () => null,
    },
},
  emits: ['update:swiperImages'],
  setup(__props, { emit: __emit }) {

const props = __props

const valueRef = toRef(props, 'value')
const menuRef = toRef(props, 'menu')
const subMenuRef = toRef(props, 'subMenu')

const emits = __emit

const updateImage = (value: any) => {
    emits('update:swiperImages', value)
}

return (_ctx: any,_cache: any) => {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (menuRef.value === 'quiz')
      ? (_openBlock(), _createBlock(_component_a_row, {
          key: 0,
          class: "event-content-properties"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              span: 24,
              class: "input-area-wrapper"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 6 }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createTextVNode("퀴즈 정답")
                  ])),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 18 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_textarea, {
                      class: "input-area",
                      value: valueRef.value.quizAnswer,
                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((valueRef.value.quizAnswer) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, {
              span: 24,
              class: "input-area-wrapper"
            }, {
              default: _withCtx(() => [
                _cache[12] || (_cache[12] = _createElementVNode("label", { class: "input-label" }, "퀴즈 코멘트", -1)),
                _createVNode(_component_a_textarea, {
                  class: "input-area",
                  placeholder: "정답 부분에 %s를 넣어주세요!",
                  value: valueRef.value.quizCommentFormat,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((valueRef.value.quizCommentFormat) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (menuRef.value === 'survey')
      ? (_openBlock(), _createBlock(_component_a_row, {
          key: 1,
          class: "event-content-properties"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              span: 24,
              class: "input-area-wrapper"
            }, {
              default: _withCtx(() => [
                _cache[13] || (_cache[13] = _createElementVNode("label", { class: "input-label" }, "최소 글자 수", -1)),
                _createVNode(_component_a_input, {
                  value: valueRef.value.surveyLongMinLength,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((valueRef.value.surveyLongMinLength) = $event)),
                  "default-value": "0"
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (menuRef.value === 'survey')
      ? (_openBlock(), _createBlock(_component_a_row, {
          key: 2,
          class: "event-content-properties"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              span: 24,
              class: "input-area-wrapper"
            }, {
              default: _withCtx(() => [
                _cache[14] || (_cache[14] = _createElementVNode("label", { class: "input-label" }, "최소 글자 수", -1)),
                _createVNode(_component_a_input, {
                  value: valueRef.value.surveyLongMinLength,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((valueRef.value.surveyLongMinLength) = $event)),
                  "default-value": "0"
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (menuRef.value === 'request')
      ? (_openBlock(), _createBlock(_component_a_row, {
          key: 3,
          class: "event-content-properties"
        }, {
          default: _withCtx(() => [
            (subMenuRef.value === 'bottom-button')
              ? (_openBlock(), _createBlock(_component_a_row, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_descriptions, {
                      layout: "horizontal",
                      column: 1,
                      bordered: true
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_descriptions_item, { label: "버튼 라벨" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: valueRef.value.bbLabel,
                              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((valueRef.value.bbLabel) = $event))
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_descriptions_item, { label: "딥링크(1순위)" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: valueRef.value.bbAppDeeplinkUrl,
                              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((valueRef.value.bbAppDeeplinkUrl) = $event))
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_descriptions_item, { label: "외부URL(2순위)" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: valueRef.value.bbAppExternalUrl,
                              "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((valueRef.value.bbAppExternalUrl) = $event))
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_descriptions_item, null, {
                          label: _withCtx(() => _cache[15] || (_cache[15] = [
                            _createElementVNode("span", { innerHTML: 'WebUrl<br/>(딥링크 또는 외부링크)' }, null, -1)
                          ])),
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: valueRef.value.bbWebUrl,
                              "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((valueRef.value.bbWebUrl) = $event))
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_descriptions_item, { label: "버튼 설명" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: valueRef.value.bbDescription,
                              "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((valueRef.value.bbDescription) = $event))
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_descriptions_item, { label: "버튼 배경색(#000000)" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: valueRef.value.bbBgColor,
                              "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((valueRef.value.bbBgColor) = $event))
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_descriptions_item, { label: "버튼 폰트색(#000000)" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: valueRef.value.bbFontColor,
                              "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((valueRef.value.bbFontColor) = $event))
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (subMenuRef.value === 'image-swiper')
              ? (_openBlock(), _createBlock(_component_a_row, {
                  key: 1,
                  class: "event-content-properties"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_descriptions, {
                      layout: "vertical",
                      column: 1,
                      bordered: true
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_descriptions_item, { label: "이미지" }, {
                          default: _withCtx(() => [
                            _createVNode(ImageMultipleUploader, {
                              values: valueRef.value.swiperImages,
                              path: "/event-swiper-images",
                              "onUpdate:value": updateImage
                            }, null, 8, ["values"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})