import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 1 }

import InputMapper from '@/components/InputMapper.vue'
import { computed, onMounted, ref } from 'vue'
import { DownOutlined, UpOutlined } from '@ant-design/icons-vue'
import { useFormStore } from '@/store/form'


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterForm',
  props: {
    name: {
        type: String,
        required: true,
        default: 'filter',
    },
    filterSpec: {
        type: Object,
        required: true,
    },
    countOfColumn: {
        type: Number,
        default: 3,
    },
    inputOnly: {
        type: Boolean,
        default: false,
    },
},
  emits: ['search'],
  setup(__props) {

const props = __props



const { formState, resetForm, getRecordKeys, setFormSpec } = useFormStore()

const expand = ref(false)

const inputCount = computed(() => {
    return getRecordKeys(props.name).length
})

const gridKeys = computed(() => {
    const sliceCount = Math.ceil(inputCount.value / props.countOfColumn)
    const sliced = []

    for (let i = 0; i < sliceCount; i++) {
        sliced.push(
            getRecordKeys(props.name).slice(
                i * props.countOfColumn,
                (i + 1) * props.countOfColumn
            )
        )
    }
    return sliced
})

const showInputCount = () => {
    return expand.value === true
        ? Math.ceil(inputCount.value / props.countOfColumn)
        : 3
}

const toggleExpand = () => {
    expand.value = !expand.value
}

onMounted(() => {
    if (
        props.name == 'filter' ||
        (props.filterSpec && !formState[props.name]) ||
        Object.keys(props.filterSpec) != Object.keys(formState[props.name].spec)
    ) {
        setFormSpec(props.name, props.filterSpec)
    }
})

return (_ctx: any,_cache: any) => {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_form, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_row, { gutter: 24 }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(gridKeys.value, (inputItems, rowIndex) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: rowIndex }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(inputItems, (filterKey, columnIndex) => {
                  return _withDirectives((_openBlock(), _createBlock(_component_a_col, {
                    key: columnIndex,
                    span: 24 / __props.countOfColumn,
                    style: {"overflow":"auto"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(InputMapper, {
                        "input-spec": _unref(formState)[__props.name].spec[filterKey],
                        onPressEnter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('search')))
                      }, null, 8, ["input-spec"])
                    ]),
                    _: 2
                  }, 1032, ["span"])), [
                    [_vShow, rowIndex < showInputCount()]
                  ])
                }), 128))
              ], 64))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, {
          span: 24,
          style: { textAlign: 'right', paddingBottom: '45px' }
        }, {
          default: _withCtx(() => [
            (!__props.inputOnly)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 0,
                  type: "primary",
                  "html-type": "submit",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('search')))
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" 조회 ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_a_button, {
              style: { marginLeft: '8px' },
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(resetForm)(__props.name)))
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" 초기화 ")
              ])),
              _: 1
            }),
            (inputCount.value > 6)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                  (expand.value == true)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        style: { marginLeft: '8px', fontSize: '12px' },
                        onClick: toggleExpand
                      }, [
                        _cache[5] || (_cache[5] = _createTextVNode(" 숨기기 ")),
                        _createVNode(_unref(UpOutlined))
                      ]))
                    : (_openBlock(), _createElementBlock("a", {
                        key: 1,
                        style: { marginLeft: '8px', fontSize: '12px' },
                        onClick: toggleExpand
                      }, [
                        _cache[6] || (_cache[6] = _createTextVNode(" 더보기 ")),
                        _createVNode(_unref(DownOutlined))
                      ]))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})