import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%"} }

import { InputComponents } from '@/components/InputMapper.vue'
import ResourceTable from '@/components/ResourceTable.vue'
import PForm from '@/components/PForm.vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import {
    formatBoolean,
    formatEnum,
    formatLocalDateTime,
} from '@/util/formmater'
import { Column } from '@/views'
import { onMounted, ref } from 'vue'
import { debounce } from 'lodash'
import { stringify } from 'qs'
import { required } from '@/util/input-validation'
import { Line } from 'vue-chartjs'
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js'
import { useEnumTypeStore } from '@/store/enumType'
import Moment from 'moment'


export default /*@__PURE__*/_defineComponent({
  __name: 'AICoachingQATool',
  setup(__props) {

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

const cgmResourceTable = ref()
const resourceTable = ref()
const queryParams = ref()

const targetUserId = ref()
const buildNumber = ref()

const filterSpec = ref({
    userId: {
        label: '사용자 ID',
        value: null,
    },
})

const aiCoachingLogFilterSpec = ref({
    userId: {
        label: '사용자 ID',
        value: null,
    },
    eventName: {
        label: '이벤트 이름',
        value: null,
    },
})

const changeTargetUserId = debounce(async (value: number | null) => {
    queryParams.value = {
        ...queryParams.value,
        userId: value,
    }
    //@ts-ignore
    filterSpec.value.userId.value = value
    resourceTable.value.reloadPage()
    cgmResourceTable.value.reloadPage()
}, 500)

const changeBuildNumber = async (value: number | null) => {
    queryParams.value = {
        ...queryParams.value,
        buildNumber: value,
    }
    await fetchFloatingEvent()
}

const auditFilterSpec = ref({
    resourceId: {
        label: '사용자 ID',
        value: filterSpec.value.userId.value || targetUserId,
    },
    action: {
        label: 'Action',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'admin-audit-action',
    },
    resource: {
        label: 'Resource',
        value: 'UserCgmDevice',
        disabled: true,
    },
})

const auditColumn = [
    {
        title: 'ID',
        dataIndex: 'id',
        sorter: true,
        fixed: 'left',
        width: 100,
    },
    {
        title: 'Action',
        dataIndex: 'action',
        customRender: formatEnum('admin-audit-action'),
        width: 70,
    },
    {
        title: 'Parameter',
        dataIndex: 'parameter',
        customRender: (col: any) => {
            if (col.value.endAt == null) return ''
            return `
            시작일시: ${Moment(col.value.endAt).format('YYYY-MM-DD HH:mm:ss')}
            종료일시: ${Moment(col.value.endAt).format('YYYY-MM-DD HH:mm:ss')}
            `
        },
    },
    {
        title: '등록자',
        dataIndex: 'createdBy',
        width: 100,
    },
    {
        title: '등록일시',
        dataIndex: 'creationTime',
        customRender: formatLocalDateTime,
        width: 165,
        sorter: true,
        align: 'center',
    },
    {
        title: '수정자',
        dataIndex: 'updatedBy',
        width: 100,
    },
    {
        title: '수정일시',
        dataIndex: 'updateTime',
        customRender: formatLocalDateTime,
        width: 165,
        sorter: true,
        align: 'center',
    },
]

const deviceColumn = [
    {
        title: 'ID',
        dataIndex: 'tsid',
        width: 120,
        fixed: 'left',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
        },
        sorter: true,
    },
    {
        title: '센서 타입',
        dataIndex: 'deviceType',
        customRender: formatEnum('cgm-device-type'),
        width: 50,
    },
    {
        title: '자동 등록',
        dataIndex: 'autoRegister',
        customRender: formatBoolean,
        width: 60,
    },
    {
        title: '무료 체험',
        dataIndex: 'freeTrial',
        customRender: formatBoolean,
        width: 60,
    },
    {
        title: '센서 상태',
        dataIndex: 'sensorStatus',
        width: 60,
    },
    {
        title: '시작일시',
        dataIndex: 'startAt',
        customRender: formatLocalDateTime,
        width: 100,
    },
    {
        title: '종료일시',
        dataIndex: 'endAt',
        customRender: formatLocalDateTime,
        width: 100,
    },
]

const deviceSpec = ref({
    startAt: {
        label: '시작일시',
        value: null,
        component: InputComponents.ADatetimePicker,
    },
    endAt: {
        label: '종료일시',
        value: null,
        component: InputComponents.ADatetimePicker,
    },
    deviceType: {
        label: '센서 타입',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'cgm-device-type',
    },
})

const deviceRules = {
    startAt: [required],
    endAt: [required],
    devcieType: [required],
}

const glucoseSpec = ref({
    targetDate: {
        label: '대상일',
        value: null,
        component: InputComponents.ADatePicker,
    },
})

const glucoseRules = {
    targetDate: [required],
}

const generateGlucoseLoading = ref(false)

const generateGlucose = async () => {
    try {
        generateGlucoseLoading.value = true
        await adminApi<ApiResponse<void>>(
            `/api/ai-coaching-schedule/generate-mock-glucose`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: targetUserId.value,
                    targetDate: glucoseSpec.value.targetDate.value,
                }),
            }
        )
    } finally {
        generateGlucoseLoading.value = false
    }
}

const deleteAiCoachingEventLog = async () => {
    await adminApi<ApiResponse<void>>(
        `/api/ai-coaching-schedule/ai-coaching-event-log?${stringify(
            queryParams.value
        )}`,
        {
            method: 'DELETE',
        }
    )

    resourceTable.value.reloadPage()
}

const scanEvent = async (targetAt: any) => {
    aiCoachingScanLoading.value = true
    try {
        aiCoachingScanLoading.value = true
        await adminApi<ApiResponse<void>>(
            `/api/ai-coaching-schedule/scan-event?targetAt=${targetAt}&${stringify(
                queryParams.value
            )}`
        )
    } finally {
        aiCoachingScanLoading.value = false
    }
}

const floatingEvent = ref()

const fetchFloatingEvent = async () => {
    floatingEvent.value = null
    const response = await adminApi<ApiResponse<any>>(
        `/api/ai-coaching-schedule/floating-event?${stringify(
            queryParams.value
        )}`
    )
    floatingEvent.value = response.result
}

const glucoseMetrics = ref<any>()

const fetchReport = async (targetDate: any) => {
    const response = await adminApi<ApiResponse<any>>(
        `/api/ai-coaching-schedule/report?targetDate=${targetDate}&${stringify(
            queryParams.value
        )}`
    )
    response.result

    if (response.result != null && response.result.interpolatedPoints != null) {
        const points = response.result.interpolatedPoints
        const labels = points.map((it: any) => it.dt)
        const data = points.map((it: any) => it.y)

        glucoseMetrics.value = {
            labels,
            datasets: [
                {
                    label: '혈당',
                    data,
                    borderColor: '#f87979',
                },
            ],
        }
    }
}

const aiCoachingSpec = ref({
    targetAt: {
        label: '대상일시',
        value: null,
        component: InputComponents.ADatetimePicker,
    },
})

const aiCoachingRules = {
    targetAt: [required],
}

const aiCoachingScanLoading = ref(false)

const columns: Array<Column> = [
    {
        title: 'eventName',
        dataIndex: 'eventName',
        fixed: 'left',
        width: 100,
    },
    {
        title: 'triggerCount',
        dataIndex: 'triggerCount',
    },
    {
        title: 'createdAt',
        dataIndex: 'createdAt',
        customRender: formatLocalDateTime,
        width: 140,
    },
    {
        title: 'triggerAt',
        dataIndex: 'triggerAt',
        customRender: formatLocalDateTime,
        width: 140,
        sorter: true,
    },
    {
        title: 'done',
        dataIndex: 'done',
        customRender: formatBoolean,
    },
    {
        title: 'contentTitle',
        dataIndex: 'contentTitle',
        width: 300,
    },
    {
        title: 'floatingMessage',
        dataIndex: 'floatingMessage',
        width: 300,
    },
    {
        title: 'floatingButton',
        dataIndex: 'floatingButton',
        width: 150,
    },
    {
        title: 'notificationTitle',
        dataIndex: 'notificationTitle',
        width: 150,
    },
    {
        title: 'notificationMessage',
        dataIndex: 'notificationMessage',
        width: 200,
    },
    {
        title: 'andyIconUrl',
        dataIndex: 'andyIconUrl',
        type: 'img',
        width: 130,
    },
    {
        title: 'showCount',
        dataIndex: 'showCount',
        width: 100,
    },
    {
        title: 'aiCoachingType',
        dataIndex: 'aiCoachingType',
        customRender: formatEnum('ai-coaching-type'),
        width: 150,
        align: 'center',
    },
    {
        title: 'timelineTitle',
        dataIndex: 'timelineTitle',
        width: 150,
        align: 'center',
    },
    {
        title: 'timelineIcon',
        dataIndex: 'timelineIcon',
        type: 'img',
        width: 130,
    },
    {
        title: 'timelineContent',
        dataIndex: 'timelineContent',
        width: 300,
    },
    {
        title: 'timelineImage',
        dataIndex: 'timelineImage',
        type: 'img',
        width: 130,
    },
    {
        title: 'timelineAnswer',
        dataIndex: 'timelineAnswer',
        width: 300,
    },
    {
        title: 'timelineButton',
        dataIndex: 'timelineButton',
        width: 150,
    },
    {
        title: 'priority',
        dataIndex: 'priority',
        sorter: true,
        fixed: 'right',
    },
]

onMounted(() => {
    const enumTypeStore = useEnumTypeStore()
    enumTypeStore.dispatchEnums([
        'ai-coaching-type',
        'cgm-device-type',
        'admin-audit-action',
    ])
})

return (_ctx: any,_cache: any) => {
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_page_header = _resolveComponent("a-page-header")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_col = _resolveComponent("a-col")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_page_header, { title: "AI 코칭 QA 도구" }, {
      extra: _withCtx(() => [
        _createVNode(_component_a_form_item, { label: "대상 사용자" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input_number, {
              value: targetUserId.value,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((targetUserId).value = $event)),
              onChange: _unref(changeTargetUserId)
            }, null, 8, ["value", "onChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { label: "빌드 번호" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input_number, {
              value: buildNumber.value,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((buildNumber).value = $event)),
              onChange: changeBuildNumber
            }, null, 8, ["value"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_row, { style: {"margin-bottom":"20px"} }, {
        default: _withCtx(() => [
          (glucoseMetrics.value)
            ? (_openBlock(), _createBlock(_unref(Line), {
                key: 0,
                data: glucoseMetrics.value
              }, null, 8, ["data"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_a_row, {
        gutter: 16,
        style: {"margin-bottom":"20px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_card, { title: "혈당 데이터 생성" }, {
                default: _withCtx(() => [
                  _createVNode(PForm, {
                    title: "혈당 데이터 생성",
                    "input-spec": glucoseSpec.value,
                    rules: glucoseRules,
                    loading: generateGlucoseLoading.value,
                    onSubmit: generateGlucose
                  }, {
                    button: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("생성")
                    ])),
                    action: _withCtx(({ form }) => [
                      _createVNode(_component_a_button, {
                        onClick: () => fetchReport(form.targetDate),
                        disabled: form.targetDate == null
                      }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode("혈당 조회 ")
                        ])),
                        _: 2
                      }, 1032, ["onClick", "disabled"])
                    ]),
                    _: 1
                  }, 8, ["input-spec", "loading"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_card, { title: "AI 코칭 갱신" }, {
                default: _withCtx(() => [
                  _cache[6] || (_cache[6] = _createTextVNode(" 대상 시간에 혈당 리포트를 기반으로 AI 코칭 이벤트를 발행한다. ")),
                  _createVNode(PForm, {
                    title: "AI 코칭 갱신",
                    "input-spec": aiCoachingSpec.value,
                    rules: aiCoachingRules,
                    loading: aiCoachingScanLoading.value,
                    onSubmit: _cache[2] || (_cache[2] = (form) => scanEvent(form.targetAt)),
                    style: {"margin-top":"20px"}
                  }, {
                    button: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("갱신")
                    ])),
                    _: 1
                  }, 8, ["input-spec", "loading"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_row, { style: {"margin-bottom":"20px"} }, {
        default: _withCtx(() => [
          (floatingEvent.value)
            ? (_openBlock(), _createBlock(_component_a_card, {
                key: 0,
                title: "플로팅 이벤트"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, { label: "eventName" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(floatingEvent.value.eventName), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, { label: "floatingMessage" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(floatingEvent.value.floatingMessage), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createVNode(ResourceTable, {
      ref_key: "cgmResourceTable",
      ref: cgmResourceTable,
      "resource-name": "cgmDevice",
      title: "CGM 센서",
      uri: "/api/ai-coaching-schedule/cgm-device",
      columns: deviceColumn,
      "filter-spec": filterSpec.value,
      "update-spec": deviceSpec.value,
      "update-rules": deviceRules,
      deletable: "",
      "load-on-mount": "",
      "primary-key": "tsid"
    }, null, 8, ["filter-spec", "update-spec"]),
    _createVNode(ResourceTable, {
      "resource-name": "cgmAudit",
      title: "CGM 센서 변경이력",
      uri: "/api/admin-audit",
      columns: auditColumn,
      "filter-spec": auditFilterSpec.value,
      "load-on-mount": "",
      scroll: { x: 1100 }
    }, null, 8, ["filter-spec"]),
    _createVNode(ResourceTable, {
      ref_key: "resourceTable",
      ref: resourceTable,
      "resource-name": "ai-coaching-event-log",
      title: "AI 코칭 이벤트 로그",
      uri: "/api/ai-coaching-schedule/ai-coaching-event-log",
      "filter-spec": aiCoachingLogFilterSpec.value,
      columns: columns,
      "load-on-mount": "",
      pageable: false,
      scroll: { x: 3000 }
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_a_button, { onClick: deleteAiCoachingEventLog }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode("로그 초기화 ")
          ])),
          _: 1
        }),
        _createVNode(_component_a_button, { onClick: fetchFloatingEvent }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode("플로팅 이벤트 조회 ")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["filter-spec"])
  ]))
}
}

})