<template>
    <a-row v-if="menuRef === 'quiz'" class="event-content-properties">
        <a-col :span="24" class="input-area-wrapper">
            <a-col :span="6">퀴즈 정답</a-col>
            <a-col :span="18">
                <a-textarea
                    class="input-area"
                    v-model:value="valueRef.quizAnswer"
                />
            </a-col>
        </a-col>
        <a-col :span="24" class="input-area-wrapper">
            <label class="input-label">퀴즈 코멘트</label>
            <a-textarea
                class="input-area"
                placeholder="정답 부분에 %s를 넣어주세요!"
                v-model:value="valueRef.quizCommentFormat"
            />
        </a-col>
    </a-row>
    <a-row v-if="menuRef === 'survey'" class="event-content-properties">
        <a-col :span="24" class="input-area-wrapper">
            <label class="input-label">최소 글자 수</label>
            <a-input
                v-model:value="valueRef.surveyLongMinLength"
                default-value="0"
            />
        </a-col>
    </a-row>
    <a-row v-if="menuRef === 'survey'" class="event-content-properties">
        <a-col :span="24" class="input-area-wrapper">
            <label class="input-label">최소 글자 수</label>
            <a-input
                v-model:value="valueRef.surveyLongMinLength"
                default-value="0"
            />
        </a-col>
    </a-row>
    <a-row v-if="menuRef === 'request'" class="event-content-properties">
        <a-row v-if="subMenuRef === 'bottom-button'">
            <a-descriptions layout="horizontal" :column="1" :bordered="true">
                <a-descriptions-item label="버튼 라벨">
                    <a-input v-model:value="valueRef.bbLabel" />
                </a-descriptions-item>
                <a-descriptions-item label="딥링크(1순위)">
                    <a-input v-model:value="valueRef.bbAppDeeplinkUrl" />
                </a-descriptions-item>
                <a-descriptions-item label="외부URL(2순위)">
                    <a-input v-model:value="valueRef.bbAppExternalUrl" />
                </a-descriptions-item>
                <a-descriptions-item>
                    <template #label>
                        <span
                            v-html="'WebUrl<br/>(딥링크 또는 외부링크)'"
                        ></span>
                    </template>
                    <a-input v-model:value="valueRef.bbWebUrl" />
                </a-descriptions-item>
                <a-descriptions-item label="버튼 설명">
                    <a-input v-model:value="valueRef.bbDescription" />
                </a-descriptions-item>
                <a-descriptions-item label="버튼 배경색(#000000)">
                    <a-input v-model:value="valueRef.bbBgColor" />
                </a-descriptions-item>
                <a-descriptions-item label="버튼 폰트색(#000000)">
                    <a-input v-model:value="valueRef.bbFontColor" />
                </a-descriptions-item>
            </a-descriptions>
        </a-row>
        <a-row
            v-if="subMenuRef === 'image-swiper'"
            class="event-content-properties"
        >
            <a-descriptions layout="vertical" :column="1" :bordered="true">
                <a-descriptions-item label="이미지">
                    <image-multiple-uploader
                        :values="valueRef.swiperImages"
                        path="/event-swiper-images"
                        @update:value="updateImage"
                    />
                </a-descriptions-item>
            </a-descriptions>
        </a-row>
    </a-row>
</template>

<style>
.event-content-properties {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
}

.input-label {
    font-weight: bold;
}

.input-area-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
</style>
<script setup lang="ts">
import ImageMultipleUploader from '@/components/input/ImageMultipleUploader.vue'
import { toRef, defineProps, defineEmits } from 'vue'

const props = defineProps({
    value: {
        type: Object,
        default: () => {},
    },
    menu: {
        type: String,
        default: () => null,
    },
    subMenu: {
        type: String,
        default: () => null,
    },
})

const valueRef = toRef(props, 'value')
const menuRef = toRef(props, 'menu')
const subMenuRef = toRef(props, 'subMenu')

const emits = defineEmits(['update:swiperImages'])

const updateImage = (value: any) => {
    emits('update:swiperImages', value)
}
</script>
