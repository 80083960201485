<template>
    <ResourceTable
        v-if="slotId"
        ref="table"
        :resource-name="`ad-instance-${Date.now()}`"
        title=""
        uri="/api/advertisements/instances"
        class="resource-table"
        :create-spec="createSpec"
        :create-rules="createRules"
        :update-spec="updateSpec"
        :update-rules="updateRules"
        :filter-spec="filterSpec"
        :columns="columns"
        :scroll="{ x: 'max-content', y: 1400 }"
        :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
        }"
        deletable
        load-on-mount
    >
        <template #actions>
            <a-button @click="() => addBaseInstance()">기본 광고</a-button>
            <a-button @click="() => addInstance()">광고 추가</a-button>
        </template>
    </ResourceTable>
</template>

<style scoped>
.resource-table {
    padding: 0 15px;
}
</style>
<script lang="ts">
import { defineComponent, Ref, ref } from 'vue'
import {
    FormSpecification,
    InputComponents,
} from '@/components/InputMapper.vue'
import { required } from '@/util/input-validation'
import ResourceTable from '@/components/ResourceTable.vue'
import { formatLocalDateTime } from '@/util/formmater'
import { useFormStore } from '@/store/form'

export default defineComponent({
    name: 'AdInstance',

    components: {
        ResourceTable,
    },

    props: {
        slotId: {
            type: Number,
        },
        minBuildNumber: {
            type: Number,
        },
        reload: {
            type: Boolean,
        },
    },

    watch: {
        slotId(value: any) {
            const { setFormValue } = useFormStore()
            if (value) {
                setFormValue('ad-instanceFilter', 'slotId', value)
                this.reloadResources()
            }
        },
    },

    setup(props: any) {
        const table = ref()
        const selectedRowKeys: Ref<Array<number>> = ref([])
        const selectedRows: Ref<Array<any>> = ref([])
        const loading = ref(false)

        const filterSpec: Ref<FormSpecification> = ref({
            slotId: {
                label: '제목',
                value: props.slotId,
                hidden: true,
            },
            minBuildNumber: {
                label: '최소 빌드 넘버',
                value: props.minBuildNumber,
                hidden: true,
            },
        })

        const columns = [
            {
                title: '',
                dataIndex: 'id',
                align: 'center',
                fixed: 'left',
                width: '48px',
            },
            {
                title: '미리보기',
                type: 'ad-preview',
                fixed: 'left',
            },
            {
                title: '세그먼트',
                type: 'ad-target-user',
                width: '88px',
                align: 'center',
            },
            {
                title: '반복 날짜',
                type: 'ad-repeat',
                width: '256px',
                align: 'center',
            },
            {
                title: '시작',
                dataIndex: 'startTime',
                align: 'center',
                width: '128px',
                customRender: formatLocalDateTime,
            },
            {
                title: '종료',
                dataIndex: 'endTime',
                align: 'center',
                width: '128px',
                customRender: formatLocalDateTime,
            },
            {
                title: '복사',
                type: 'copy',
                align: 'center',
                fixed: 'right',
            },
        ]
        const createSpec: Ref<FormSpecification> = ref({
            name: {
                label: '광고명',
                value: null,
            },
            eventHandler: {
                label: '랜딩 URL',
                value: {
                    url: null,
                    type: null,
                    keyword: null,
                },
                component: InputComponents.AdEventHandlerInput,
            },
            layout: {
                label: '속성값',
                value: {
                    layoutId: null,
                    layoutProperties: {},
                },
                component: InputComponents.AdLayoutInputV2,
            },
            targetUser: {
                label: '타켓 설정',
                value: {
                    segId: null,
                },
                component: InputComponents.AdTargetUserInput,
            },
            schedule: {
                label: '날짜 설정',
                value: {
                    repeatDayOfWeeks: [],
                    repeatStartTime: null,
                    repeatEndTime: null,
                },
                component: InputComponents.AdScheduleInput,
            },
            requirement: {
                label: '광고 포인트 조건',
                value: {
                    type: null,
                    columnId: null,
                },
                component: InputComponents.AdRequirementInput,
            },
        })
        const updateSpec: Ref<FormSpecification> = ref({
            enable: {
                label: '상태',
                value: null,
                component: InputComponents.BooleanInput,
                notNull: true,
                trueText: '노출',
                falseText: '비노출',
            },
            name: {
                label: '광고명',
                value: null,
            },
            eventHandler: {
                label: '이벤트 처리',
                component: InputComponents.AdEventHandlerInput,
            },
            layout: {
                label: '레이아웃',
                value: {
                    layoutId: null,
                    layoutProperties: {},
                },
                component: InputComponents.AdLayoutInputV2,
            },
            targetUser: {
                label: '타켓 설정',
                value: {
                    segId: null,
                },
                component: InputComponents.AdTargetUserInput,
            },
            schedule: {
                label: '날짜 설정',
                value: {
                    repeatDayOfWeeks: [],
                    repeatStartTime: null,
                    repeatEndTime: null,
                },
                component: InputComponents.AdScheduleInput,
            },
            requirement: {
                label: '광고 포인트 조건',
                value: {
                    type: null,
                },
                component: InputComponents.AdRequirementInput,
            },
        })
        const createRules = {
            name: [required],
            eventHandlerType: [required],
            layoutId: [required],
            layoutProperties: [required],
        }
        const updateRules = {
            enable: [required],
            ...createRules,
        }

        return {
            table,
            columns,
            createSpec,
            createRules,
            updateSpec,
            updateRules,
            filterSpec,
            selectedRowKeys,
            selectedRows,
            loading,
        }
    },

    emits: ['update:baseInstance', 'update:instances'],

    methods: {
        onSelectChange(
            selectedRowKeys: Array<number>,
            selectedRows: Array<any>
        ) {
            this.selectedRows = selectedRows
            this.selectedRowKeys = selectedRowKeys
        },
        addBaseInstance() {
            this.$emit('update:baseInstance', this.selectedRows)
            this.selectedRows = []
            this.selectedRowKeys = []
        },
        addInstance() {
            this.$emit('update:instances', this.selectedRows)
            this.selectedRows = []
            this.selectedRowKeys = []
        },
        reloadResources() {
            this.table.reloadPage()
        },
    },
})
</script>
