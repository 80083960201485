import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_outlined = _resolveComponent("home-outlined")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_sub_menu = _resolveComponent("a-sub-menu")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!
  const _component_menu_unfold_outlined = _resolveComponent("menu-unfold-outlined")!
  const _component_menu_fold_outlined = _resolveComponent("menu-fold-outlined")!
  const _component_UserProfile = _resolveComponent("UserProfile")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, { id: "app-layout-fold-trigger" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_sider, {
        id: "side",
        breakpoint: "lg",
        collapsed: _ctx.collapsed,
        "onUpdate:collapsed": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.collapsed) = $event)),
        trigger: null,
        "collapsed-width": "100",
        collapsible: ""
      }, {
        default: _withCtx(() => [
          _cache[150] || (_cache[150] = _createElementVNode("div", { class: "logo" }, [
            _createElementVNode("img", { src: "img/icons/apple-icon-72x72.png" })
          ], -1)),
          _createVNode(_component_a_menu, {
            id: "side__menu",
            theme: "light",
            mode: "inline",
            "default-selected-keys": _ctx.currentMenus
          }, {
            default: _withCtx(() => [
              (_ctx.authorities.includes(_ctx.Role.RESEARCHER))
                ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/home" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_home_outlined),
                      _cache[1] || (_cache[1] = _createElementVNode("span", null, "Home", -1)),
                      _createVNode(_component_router_link, { to: "/" })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.authorities.includes(_ctx.Role.RESEARCHER))
                ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/userFeedback" }, {
                    default: _withCtx(() => [
                      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "anticon emoji-icon" }, " 📝 ", -1)),
                      _cache[3] || (_cache[3] = _createElementVNode("span", null, "사용자 피드백", -1)),
                      _createVNode(_component_router_link, { to: "/userFeedback" })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_a_sub_menu, {
                key: "/productGroup",
                title: "제품 & 이미지"
              }, {
                icon: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createElementVNode("span", { class: "emoji-icon anticon" }, "💊", -1)
                ])),
                default: _withCtx(() => [
                  (_ctx.authorities.includes(_ctx.Role.RESEARCHER))
                    ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/products" }, {
                        default: _withCtx(() => [
                          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "emoji-icon anticon" }, "💊", -1)),
                          _cache[6] || (_cache[6] = _createElementVNode("span", null, "영양제", -1)),
                          _createVNode(_component_router_link, { to: "/products" })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.authorities.includes(_ctx.Role.RESEARCHER))
                    ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/brand" }, {
                        default: _withCtx(() => [
                          _cache[7] || (_cache[7] = _createElementVNode("span", { class: "emoji-icon anticon" }, "🏷️", -1)),
                          _cache[8] || (_cache[8] = _createElementVNode("span", null, "브랜드", -1)),
                          _createVNode(_component_router_link, { to: "/brand" })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.authorities.includes(_ctx.Role.ADMIN))
                    ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/productTag" }, {
                        default: _withCtx(() => [
                          _cache[9] || (_cache[9] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📛", -1)),
                          _cache[10] || (_cache[10] = _createElementVNode("span", null, "제품 태그", -1)),
                          _createVNode(_component_router_link, { to: "/productTag" })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_a_menu_item, { key: "/fileManager" }, {
                    default: _withCtx(() => [
                      _cache[11] || (_cache[11] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📁", -1)),
                      _cache[12] || (_cache[12] = _createElementVNode("span", null, "이미지 파일 업로더", -1)),
                      _createVNode(_component_router_link, { to: "/fileManager" })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_sub_menu, {
                key: "/formeDiet",
                title: "포미다이어트"
              }, {
                icon: _withCtx(() => _cache[13] || (_cache[13] = [
                  _createElementVNode("span", { class: "emoji-icon anticon" }, "🧑‍🏫", -1)
                ])),
                default: _withCtx(() => [
                  _createVNode(_component_a_menu_item, { key: "/formeDiet" }, {
                    default: _withCtx(() => [
                      _cache[14] || (_cache[14] = _createElementVNode("span", { class: "emoji-icon anticon" }, "👥", -1)),
                      _cache[15] || (_cache[15] = _createElementVNode("span", null, "유저 리스트", -1)),
                      _createVNode(_component_router_link, { to: "/formeDiet" })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_menu_item, { key: "/userDietResult" }, {
                    default: _withCtx(() => [
                      _cache[16] || (_cache[16] = _createElementVNode("span", { class: "emoji-icon anticon" }, "🌟", -1)),
                      _cache[17] || (_cache[17] = _createElementVNode("span", null, "감량 성과", -1)),
                      _createVNode(_component_router_link, { to: "/userDietResult" })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.authorities.includes(_ctx.Role.ADMIN))
                ? (_openBlock(), _createBlock(_component_a_sub_menu, {
                    key: "/nutrientGroup",
                    title: "영양소 & 개인화"
                  }, {
                    icon: _withCtx(() => _cache[18] || (_cache[18] = [
                      _createElementVNode("span", { class: "emoji-icon anticon" }, "🍎", -1)
                    ])),
                    default: _withCtx(() => [
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/nutrient" }, {
                            default: _withCtx(() => [
                              _cache[19] || (_cache[19] = _createElementVNode("span", { class: "emoji-icon anticon" }, "🍎", -1)),
                              _cache[20] || (_cache[20] = _createElementVNode("span", null, "영양소", -1)),
                              _createVNode(_component_router_link, { to: "/nutrient" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/nutrientGroup" }, {
                            default: _withCtx(() => [
                              _cache[21] || (_cache[21] = _createElementVNode("span", { class: "emoji-icon anticon" }, "🌳", -1)),
                              _cache[22] || (_cache[22] = _createElementVNode("span", null, "영양소 그룹", -1)),
                              _createVNode(_component_router_link, { to: "/nutrientGroup" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/nutrientAllowanceGroup" }, {
                            default: _withCtx(() => [
                              _cache[23] || (_cache[23] = _createElementVNode("span", { class: "emoji-icon anticon" }, "🥗", -1)),
                              _cache[24] || (_cache[24] = _createElementVNode("span", null, "영양소 섭취량 그룹", -1)),
                              _createVNode(_component_router_link, { to: "/nutrientAllowanceGroup" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/nutrientAllowance" }, {
                            default: _withCtx(() => [
                              _cache[25] || (_cache[25] = _createElementVNode("span", { class: "emoji-icon anticon" }, "⚖️", -1)),
                              _cache[26] || (_cache[26] = _createElementVNode("span", null, "섭취량", -1)),
                              _createVNode(_component_router_link, { to: "/nutrientAllowance" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/nutrientGoodPoint" }, {
                            default: _withCtx(() => [
                              _cache[27] || (_cache[27] = _createElementVNode("span", { class: "emoji-icon anticon" }, "👍", -1)),
                              _cache[28] || (_cache[28] = _createElementVNode("span", null, "영양소 좋은점", -1)),
                              _createVNode(_component_router_link, { to: "/nutrientGoodPoint" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/standardUnit" }, {
                            default: _withCtx(() => [
                              _cache[29] || (_cache[29] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📏", -1)),
                              _cache[30] || (_cache[30] = _createElementVNode("span", null, "영양소 단위", -1)),
                              _createVNode(_component_router_link, { to: "/standardUnit" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/hig" }, {
                            default: _withCtx(() => [
                              _cache[31] || (_cache[31] = _createElementVNode("span", { class: "emoji-icon anticon" }, "🎯", -1)),
                              _cache[32] || (_cache[32] = _createElementVNode("span", null, " HIG (건강목표) ", -1)),
                              _createVNode(_component_router_link, { to: "/hig" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/intakeAdvisor" }, {
                            default: _withCtx(() => [
                              _cache[33] || (_cache[33] = _createElementVNode("span", { class: "emoji-icon anticon" }, "🚦", -1)),
                              _cache[34] || (_cache[34] = _createElementVNode("span", null, " 신호등 Flag ", -1)),
                              _createVNode(_component_router_link, { to: "/intakeAdvisor" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/healthStatus" }, {
                            default: _withCtx(() => [
                              _cache[35] || (_cache[35] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📊", -1)),
                              _cache[36] || (_cache[36] = _createElementVNode("span", null, " 건강 상태 ", -1)),
                              _createVNode(_component_router_link, { to: "/healthStatus" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.authorities.includes(_ctx.Role.ADMIN))
                ? (_openBlock(), _createBlock(_component_a_sub_menu, {
                    key: "/ad",
                    title: "디스플레이 광고"
                  }, {
                    icon: _withCtx(() => _cache[37] || (_cache[37] = [
                      _createElementVNode("span", { class: "emoji-icon anticon" }, "📰", -1)
                    ])),
                    default: _withCtx(() => [
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/adRevenue" }, {
                            default: _withCtx(() => [
                              _cache[38] || (_cache[38] = _createElementVNode("span", { class: "emoji-icon anticon" }, "💰", -1)),
                              _cache[39] || (_cache[39] = _createElementVNode("span", null, " 광고 매출 ", -1)),
                              _createVNode(_component_router_link, { to: "/adRevenue" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/ad/layout" }, {
                            default: _withCtx(() => [
                              _cache[40] || (_cache[40] = _createElementVNode("span", { class: "emoji-icon anticon" }, "😵", -1)),
                              _cache[41] || (_cache[41] = _createElementVNode("span", null, "레이아웃", -1)),
                              _createVNode(_component_router_link, { to: "/ad/layout" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/ad/slot" }, {
                            default: _withCtx(() => [
                              _cache[42] || (_cache[42] = _createElementVNode("span", { class: "emoji-icon anticon" }, "😵", -1)),
                              _cache[43] || (_cache[43] = _createElementVNode("span", null, "구좌", -1)),
                              _createVNode(_component_router_link, { to: "/ad/slot" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/ad/advertise" }, {
                            default: _withCtx(() => [
                              _cache[44] || (_cache[44] = _createElementVNode("span", { class: "emoji-icon anticon" }, "💡", -1)),
                              _cache[45] || (_cache[45] = _createElementVNode("span", null, "광고 설정", -1)),
                              _createVNode(_component_router_link, { to: "/ad/advertise" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/ad/benefit/section" }, {
                            default: _withCtx(() => [
                              _cache[46] || (_cache[46] = _createElementVNode("span", { class: "emoji-icon anticon" }, "💡", -1)),
                              _cache[47] || (_cache[47] = _createElementVNode("span", null, "혜택 탭 섹션", -1)),
                              _createVNode(_component_router_link, { to: "/ad/benefit/section" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/ad/benefit/screen" }, {
                            default: _withCtx(() => [
                              _cache[48] || (_cache[48] = _createElementVNode("span", { class: "emoji-icon anticon" }, "💡", -1)),
                              _cache[49] || (_cache[49] = _createElementVNode("span", null, "혜택 탭 설정", -1)),
                              _createVNode(_component_router_link, { to: "/ad/benefit/screen" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.authorities.includes(_ctx.Role.ADMIN))
                ? (_openBlock(), _createBlock(_component_a_sub_menu, {
                    key: "/searchAd",
                    title: "검색 광고"
                  }, {
                    icon: _withCtx(() => _cache[50] || (_cache[50] = [
                      _createElementVNode("span", { class: "emoji-icon anticon" }, " 🔍", -1)
                    ])),
                    default: _withCtx(() => [
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/searchAd" }, {
                            default: _withCtx(() => [
                              _cache[51] || (_cache[51] = _createElementVNode("span", { class: "emoji-icon anticon" }, "👬🏾", -1)),
                              _cache[52] || (_cache[52] = _createElementVNode("span", null, "검색 광고 관리", -1)),
                              _createVNode(_component_router_link, { to: "/searchAd" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/productSearchKeywordRank" }, {
                            default: _withCtx(() => [
                              _cache[53] || (_cache[53] = _createElementVNode("span", { class: "emoji-icon anticon" }, "👬🏾", -1)),
                              _cache[54] || (_cache[54] = _createElementVNode("span", null, "영양제 인기 검색어 관리", -1)),
                              _createVNode(_component_router_link, { to: "/productSearchKeywordRank" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.authorities.includes(_ctx.Role.ADMIN))
                ? (_openBlock(), _createBlock(_component_a_sub_menu, {
                    key: "/event",
                    title: "이벤트"
                  }, {
                    icon: _withCtx(() => _cache[55] || (_cache[55] = [
                      _createElementVNode("span", { class: "emoji-icon anticon" }, "🎉", -1)
                    ])),
                    default: _withCtx(() => [
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/event" }, {
                            default: _withCtx(() => [
                              _cache[56] || (_cache[56] = _createElementVNode("span", { class: "emoji-icon anticon" }, "🎉", -1)),
                              _cache[57] || (_cache[57] = _createElementVNode("span", null, " 이벤트 ", -1)),
                              _createVNode(_component_router_link, { to: "/event" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/eventUser" }, {
                            default: _withCtx(() => [
                              _cache[58] || (_cache[58] = _createElementVNode("span", { class: "emoji-icon anticon" }, "👥", -1)),
                              _cache[59] || (_cache[59] = _createElementVNode("span", null, " 이벤트 참가자 ", -1)),
                              _createVNode(_component_router_link, { to: "/eventUser" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/eventUserAlarm" }, {
                            default: _withCtx(() => [
                              _cache[60] || (_cache[60] = _createElementVNode("span", { class: "emoji-icon anticon" }, "🔔", -1)),
                              _cache[61] || (_cache[61] = _createElementVNode("span", null, " 이벤트 사전알림 ", -1)),
                              _createVNode(_component_router_link, { to: "/eventUserAlarm" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/eventToast" }, {
                            default: _withCtx(() => [
                              _cache[62] || (_cache[62] = _createElementVNode("span", { class: "emoji-icon anticon" }, "🍞", -1)),
                              _cache[63] || (_cache[63] = _createElementVNode("span", null, " 이벤트 토스트 ", -1)),
                              _createVNode(_component_router_link, { to: "/eventToast" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/popup" }, {
                            default: _withCtx(() => [
                              _cache[64] || (_cache[64] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📢", -1)),
                              _cache[65] || (_cache[65] = _createElementVNode("span", null, " 팝업 ", -1)),
                              _createVNode(_component_router_link, { to: "/popup" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/userSegment" }, {
                            default: _withCtx(() => [
                              _cache[66] || (_cache[66] = _createElementVNode("span", { class: "emoji-icon anticon" }, "👬🏾", -1)),
                              _cache[67] || (_cache[67] = _createElementVNode("span", null, " 사용자 세그먼트 ", -1)),
                              _createVNode(_component_router_link, { to: "/userSegment" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/crm-templates" }, {
                            default: _withCtx(() => [
                              _cache[68] || (_cache[68] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📨", -1)),
                              _cache[69] || (_cache[69] = _createElementVNode("span", null, " CRM 템플릿 ", -1)),
                              _createVNode(_component_router_link, { to: "/crm-templates" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.authorities.includes(_ctx.Role.RESEARCHER))
                ? (_openBlock(), _createBlock(_component_a_sub_menu, {
                    key: "/foodIntakeAdvisor",
                    title: "식단"
                  }, {
                    icon: _withCtx(() => _cache[70] || (_cache[70] = [
                      _createElementVNode("span", { class: "emoji-icon anticon" }, "🍽️", -1)
                    ])),
                    default: _withCtx(() => [
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/diet" }, {
                            default: _withCtx(() => [
                              _cache[71] || (_cache[71] = _createElementVNode("span", { class: "emoji-icon anticon" }, "🍽️", -1)),
                              _cache[72] || (_cache[72] = _createElementVNode("span", null, "식단 관리", -1)),
                              _createVNode(_component_router_link, { to: "/diet" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/foodGuideDoc" }, {
                            default: _withCtx(() => [
                              _cache[73] || (_cache[73] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📖", -1)),
                              _cache[74] || (_cache[74] = _createElementVNode("span", null, "식단 섭취 가이드", -1)),
                              _createVNode(_component_router_link, { to: "/foodGuideDoc" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/foodIntakeAdvisor" }, {
                            default: _withCtx(() => [
                              _cache[75] || (_cache[75] = _createElementVNode("span", { class: "emoji-icon anticon" }, "🚦", -1)),
                              _cache[76] || (_cache[76] = _createElementVNode("span", null, "식단 신호등", -1)),
                              _createVNode(_component_router_link, { to: "/foodIntakeAdvisor" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.RESEARCHER))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/food" }, {
                            default: _withCtx(() => [
                              _cache[77] || (_cache[77] = _createElementVNode("span", { class: "emoji-icon anticon" }, "🍖", -1)),
                              _cache[78] || (_cache[78] = _createElementVNode("span", null, " 식품 ", -1)),
                              _createVNode(_component_router_link, { to: "/food" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.RESEARCHER))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/foodManagementByPriority" }, {
                            default: _withCtx(() => [
                              _cache[79] || (_cache[79] = _createElementVNode("span", { class: "emoji-icon anticon" }, "🍖", -1)),
                              _cache[80] || (_cache[80] = _createElementVNode("span", null, "직접등록 식품", -1)),
                              _createVNode(_component_router_link, { to: "/foodManagementByPriority" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/foodDictionary" }, {
                            default: _withCtx(() => [
                              _cache[81] || (_cache[81] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📖", -1)),
                              _cache[82] || (_cache[82] = _createElementVNode("span", null, "식품 사전", -1)),
                              _createVNode(_component_router_link, { to: "/foodDictionary" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/foodSynonym" }, {
                            default: _withCtx(() => [
                              _cache[83] || (_cache[83] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📖", -1)),
                              _cache[84] || (_cache[84] = _createElementVNode("span", null, "식품 동의어", -1)),
                              _createVNode(_component_router_link, { to: "/foodSynonym" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/andy-chance" }, {
                            default: _withCtx(() => [
                              _cache[85] || (_cache[85] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📖", -1)),
                              _cache[86] || (_cache[86] = _createElementVNode("span", null, "앤디 찬스", -1)),
                              _createVNode(_component_router_link, { to: "/andyChance" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/foodLensMap" }, {
                            default: _withCtx(() => [
                              _cache[87] || (_cache[87] = _createElementVNode("span", { class: "emoji-icon anticon" }, "🔎", -1)),
                              _cache[88] || (_cache[88] = _createElementVNode("span", null, "푸드렌즈 매칭", -1)),
                              _createVNode(_component_router_link, { to: "/foodLensMap" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/intakeUnit" }, {
                            default: _withCtx(() => [
                              _cache[89] || (_cache[89] = _createElementVNode("span", { class: "emoji-icon anticon" }, "🍴", -1)),
                              _cache[90] || (_cache[90] = _createElementVNode("span", null, "음식 섭취단위", -1)),
                              _createVNode(_component_router_link, { to: "/intakeUnit" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.authorities.includes(_ctx.Role.RESEARCHER))
                ? (_openBlock(), _createBlock(_component_a_sub_menu, {
                    key: "/workout",
                    title: "운동"
                  }, {
                    icon: _withCtx(() => _cache[91] || (_cache[91] = [
                      _createElementVNode("span", { class: "emoji-icon anticon" }, "🏃", -1)
                    ])),
                    default: _withCtx(() => [
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/workout" }, {
                            default: _withCtx(() => [
                              _cache[92] || (_cache[92] = _createElementVNode("span", { class: "emoji-icon anticon" }, "🏃", -1)),
                              _cache[93] || (_cache[93] = _createElementVNode("span", null, "운동 관리", -1)),
                              _createVNode(_component_router_link, { to: "/workout" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/workoutTrackerActivity" }, {
                            default: _withCtx(() => [
                              _cache[94] || (_cache[94] = _createElementVNode("span", { class: "emoji-icon anticon" }, "🏃", -1)),
                              _cache[95] || (_cache[95] = _createElementVNode("span", null, "헬스 트래커 운동 관리", -1)),
                              _createVNode(_component_router_link, { to: "/workoutTrackerActivity" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.authorities.includes(_ctx.Role.ADMIN))
                ? (_openBlock(), _createBlock(_component_a_sub_menu, {
                    key: "/glucose",
                    title: "혈당"
                  }, {
                    icon: _withCtx(() => _cache[96] || (_cache[96] = [
                      _createElementVNode("span", { class: "emoji-icon anticon" }, "🩸", -1)
                    ])),
                    default: _withCtx(() => [
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/aiCoachingSchedule" }, {
                            default: _withCtx(() => [
                              _cache[97] || (_cache[97] = _createElementVNode("span", { class: "emoji-icon anticon" }, "🤖", -1)),
                              _cache[98] || (_cache[98] = _createElementVNode("span", null, " AI 코칭 스케쥴 ", -1)),
                              _createVNode(_component_router_link, { to: "/aiCoachingSchedule" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/librePractice" }, {
                            default: _withCtx(() => [
                              _cache[99] || (_cache[99] = _createElementVNode("span", { class: "emoji-icon anticon" }, "🏥", -1)),
                              _cache[100] || (_cache[100] = _createElementVNode("span", null, " 리브레 병원 관리 ", -1)),
                              _createVNode(_component_router_link, { to: "/librePractice" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/aiCoachingQATool" }, {
                            default: _withCtx(() => [
                              _cache[101] || (_cache[101] = _createElementVNode("span", { class: "emoji-icon anticon" }, "🧰", -1)),
                              _cache[102] || (_cache[102] = _createElementVNode("span", null, " CGM 기기 관리 / AI 코칭 QA ", -1)),
                              _createVNode(_component_router_link, { to: "/aiCoachingQATool" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.authorities.includes(_ctx.Role.RESEARCHER))
                ? (_openBlock(), _createBlock(_component_a_sub_menu, {
                    key: "/reviews",
                    title: "리뷰"
                  }, {
                    icon: _withCtx(() => _cache[103] || (_cache[103] = [
                      _createElementVNode("span", { class: "emoji-icon anticon" }, "📝", -1)
                    ])),
                    default: _withCtx(() => [
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/review" }, {
                            default: _withCtx(() => [
                              _cache[104] || (_cache[104] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📝", -1)),
                              _cache[105] || (_cache[105] = _createElementVNode("span", null, " 영양제 리뷰 관리 ", -1)),
                              _createVNode(_component_router_link, { to: "/review" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/product/reviews" }, {
                            default: _withCtx(() => [
                              _cache[106] || (_cache[106] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📝", -1)),
                              _cache[107] || (_cache[107] = _createElementVNode("span", null, " 상품 리뷰 관리 ", -1)),
                              _createVNode(_component_router_link, { to: "/product/reviews" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.authorities.includes(_ctx.Role.RESEARCHER))
                ? (_openBlock(), _createBlock(_component_a_sub_menu, {
                    key: "/point-shop",
                    title: "포인트 샵"
                  }, {
                    icon: _withCtx(() => _cache[108] || (_cache[108] = [
                      _createElementVNode("span", { class: "emoji-icon anticon" }, "🎁", -1)
                    ])),
                    default: _withCtx(() => [
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/point-shop/products/category" }, {
                            default: _withCtx(() => [
                              _cache[109] || (_cache[109] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📝", -1)),
                              _cache[110] || (_cache[110] = _createElementVNode("span", null, " 카테고리 관리 ", -1)),
                              _createVNode(_component_router_link, { to: "/point-shop/products/category" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/point-shop/products" }, {
                            default: _withCtx(() => [
                              _cache[111] || (_cache[111] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📝", -1)),
                              _cache[112] || (_cache[112] = _createElementVNode("span", null, " 상품 관리 ", -1)),
                              _createVNode(_component_router_link, { to: "/point-shop/products" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/point-shop/orders" }, {
                            default: _withCtx(() => [
                              _cache[113] || (_cache[113] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📝", -1)),
                              _cache[114] || (_cache[114] = _createElementVNode("span", null, " 주문 관리 ", -1)),
                              _createVNode(_component_router_link, { to: "/point-shop/orders" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.authorities.includes(_ctx.Role.ADMIN))
                ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/benefit" }, {
                    default: _withCtx(() => [
                      _cache[115] || (_cache[115] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📰", -1)),
                      _cache[116] || (_cache[116] = _createElementVNode("span", null, "혜택", -1)),
                      _createVNode(_component_router_link, { to: "/benefit" })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.authorities.includes(_ctx.Role.ADMIN))
                ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/column" }, {
                    default: _withCtx(() => [
                      _cache[117] || (_cache[117] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📰", -1)),
                      _cache[118] || (_cache[118] = _createElementVNode("span", null, "필라이즈 칼럼", -1)),
                      _createVNode(_component_router_link, { to: "/column" })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.authorities.includes(_ctx.Role.ADMIN))
                ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/press" }, {
                    default: _withCtx(() => [
                      _cache[119] || (_cache[119] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📰", -1)),
                      _cache[120] || (_cache[120] = _createElementVNode("span", null, "필라이즈 프레스", -1)),
                      _createVNode(_component_router_link, { to: "/press" })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.authorities.includes(_ctx.Role.PHARMACIST))
                ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/question" }, {
                    default: _withCtx(() => [
                      _cache[121] || (_cache[121] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📝", -1)),
                      _cache[122] || (_cache[122] = _createElementVNode("span", null, "질문 관리", -1)),
                      _createVNode(_component_router_link, { to: "/question" })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.authorities.includes(_ctx.Role.ADMIN))
                ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/reviewTag" }, {
                    default: _withCtx(() => [
                      _cache[123] || (_cache[123] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📝", -1)),
                      _cache[124] || (_cache[124] = _createElementVNode("span", null, " 리뷰 태그 ", -1)),
                      _createVNode(_component_router_link, { to: "/reviewTag" })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.authorities.includes(_ctx.Role.ADMIN))
                ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/user" }, {
                    default: _withCtx(() => [
                      _cache[125] || (_cache[125] = _createElementVNode("span", { class: "emoji-icon anticon" }, "👥", -1)),
                      _cache[126] || (_cache[126] = _createElementVNode("span", null, " 사용자 ", -1)),
                      _createVNode(_component_router_link, { to: "/user" })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.authorities.includes(_ctx.Role.ADMIN))
                ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/triggerBox" }, {
                    default: _withCtx(() => [
                      _cache[127] || (_cache[127] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📦", -1)),
                      _cache[128] || (_cache[128] = _createElementVNode("span", null, " 트리거 박스 ", -1)),
                      _createVNode(_component_RouterLink, { to: "/triggerBox" })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.authorities.includes(_ctx.Role.ADMIN))
                ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/survey" }, {
                    default: _withCtx(() => [
                      _cache[129] || (_cache[129] = _createElementVNode("span", { class: "emoji-icon anticon" }, "☑️", -1)),
                      _cache[130] || (_cache[130] = _createElementVNode("span", null, " 설문 관리 ", -1)),
                      _createVNode(_component_RouterLink, { to: "/survey" })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.authorities.includes(_ctx.Role.ADMIN))
                ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/pillyzeImageAsset" }, {
                    default: _withCtx(() => [
                      _cache[131] || (_cache[131] = _createElementVNode("span", { class: "emoji-icon anticon" }, "️🖼️", -1)),
                      _cache[132] || (_cache[132] = _createElementVNode("span", null, " 파일 관리 ", -1)),
                      _createVNode(_component_RouterLink, { to: "/pillyzeImageAsset" })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.authorities.includes(_ctx.Role.ADMIN))
                ? (_openBlock(), _createBlock(_component_a_sub_menu, {
                    key: "/notification",
                    title: "알림 센터"
                  }, {
                    icon: _withCtx(() => _cache[133] || (_cache[133] = [
                      _createElementVNode("span", { class: "emoji-icon anticon" }, "📢", -1)
                    ])),
                    default: _withCtx(() => [
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/notification" }, {
                            default: _withCtx(() => [
                              _cache[134] || (_cache[134] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📢", -1)),
                              _cache[135] || (_cache[135] = _createElementVNode("span", null, "공지사항 및 이벤트 알림", -1)),
                              _createVNode(_component_router_link, { to: "/adminNotification" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/notification/release" }, {
                            default: _withCtx(() => [
                              _cache[136] || (_cache[136] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📝", -1)),
                              _cache[137] || (_cache[137] = _createElementVNode("span", null, "신규기능 알림", -1)),
                              _createVNode(_component_router_link, { to: "/notification/release" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.authorities.includes(_ctx.Role.ADMIN))
                ? (_openBlock(), _createBlock(_component_a_sub_menu, {
                    key: "/consumerGuideGroup",
                    title: "연구소팁"
                  }, {
                    icon: _withCtx(() => _cache[138] || (_cache[138] = [
                      _createElementVNode("span", { class: "emoji-icon anticon" }, "📖", -1)
                    ])),
                    default: _withCtx(() => [
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/consumerGuide" }, {
                            default: _withCtx(() => [
                              _cache[139] || (_cache[139] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📖", -1)),
                              _cache[140] || (_cache[140] = _createElementVNode("span", null, "연구소 팁 분류", -1)),
                              _createVNode(_component_router_link, { to: "/consumerGuide" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/consumerGuideDoc" }, {
                            default: _withCtx(() => [
                              _cache[141] || (_cache[141] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📖", -1)),
                              _cache[142] || (_cache[142] = _createElementVNode("span", null, "연구소 팁", -1)),
                              _createVNode(_component_router_link, { to: "/consumerGuideDoc" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.authorities.includes(_ctx.Role.ADMIN))
                ? (_openBlock(), _createBlock(_component_a_sub_menu, {
                    key: 22,
                    title: "개발자 도구"
                  }, {
                    icon: _withCtx(() => _cache[143] || (_cache[143] = [
                      _createElementVNode("span", { class: "emoji-icon anticon" }, "🛠️", -1)
                    ])),
                    default: _withCtx(() => [
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/notificationDevtool" }, {
                            default: _withCtx(() => [
                              _cache[144] || (_cache[144] = _createElementVNode("span", { class: "emoji-icon anticon" }, "📢", -1)),
                              _cache[145] || (_cache[145] = _createElementVNode("span", null, "알림 테스트", -1)),
                              _createVNode(_component_router_link, { to: "/notificationDevtool" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/openAiChat" }, {
                            default: _withCtx(() => [
                              _cache[146] || (_cache[146] = _createElementVNode("span", { class: "emoji-icon anticon" }, "🤖", -1)),
                              _cache[147] || (_cache[147] = _createElementVNode("span", null, "ChatGPT 프롬프트 엔지니어링", -1)),
                              _createVNode(_component_router_link, { to: "/openAiChat" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.authorities.includes(_ctx.Role.ADMIN))
                        ? (_openBlock(), _createBlock(_component_a_menu_item, { key: "/qaTool" }, {
                            default: _withCtx(() => [
                              _cache[148] || (_cache[148] = _createElementVNode("span", { class: "emoji-icon anticon" }, "🛠️", -1)),
                              _cache[149] || (_cache[149] = _createElementVNode("span", null, "QA 도구", -1)),
                              _createVNode(_component_router_link, { to: "/qaTool" })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["default-selected-keys"])
        ]),
        _: 1
      }, 8, ["collapsed"]),
      _createVNode(_component_a_layout, { class: "main-section" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_layout_header, { style: {"background":"#fff","padding":"0","display":"flex","align-items":"center","justify-content":"space-between"} }, {
            default: _withCtx(() => [
              (_ctx.collapsed)
                ? (_openBlock(), _createBlock(_component_menu_unfold_outlined, {
                    key: 0,
                    class: "trigger",
                    onClick: _ctx.toggleCollapsed
                  }, null, 8, ["onClick"]))
                : (_openBlock(), _createBlock(_component_menu_fold_outlined, {
                    key: 1,
                    class: "trigger",
                    onClick: _ctx.toggleCollapsed
                  }, null, 8, ["onClick"])),
              _createTextVNode(" " + _toDisplayString(`${_ctx.user.userName}, 안녕하세요 (API: ${_ctx.apiServerAddress})`) + " ", 1),
              _createVNode(_component_UserProfile, { id: "user-profile-dropdown-menu" })
            ]),
            _: 1
          }),
          (_ctx.user.loginType === 'AWS_COGNITO' && _ctx.user.id == null)
            ? (_openBlock(), _createBlock(_component_a_alert, {
                key: 0,
                message: "계정 연동이 필요합니다. 오른쪽 상단 사용자 프로필에서 정보 -> 사용 유저 아이디를 설정해주세요.",
                type: "warning",
                "show-icon": "",
                banner: ""
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_a_layout_content, { style: {
                    margin: '24px 16px',
                    padding: '24px',
                    background: '#fff',
                    overflow: 'auto',
                    height: '100vh',
                } }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}