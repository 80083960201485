import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { style: {"font-weight":"600"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_user_outlined = _resolveComponent("user-outlined")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_auto_complete_input = _resolveComponent("auto-complete-input")!
  const _component_image_input = _resolveComponent("image-input")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_dropdown_button = _resolveComponent("a-dropdown-button")!

  return (_openBlock(), _createBlock(_component_a_dropdown_button, {
    id: _ctx.id,
    class: "profile-dropdown"
  }, {
    overlay: _withCtx(() => [
      _createVNode(_component_a_menu, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_menu_item, {
            key: "1",
            onClick: _ctx.showUser
          }, {
            default: _withCtx(() => _cache[13] || (_cache[13] = [
              _createTextVNode("정보")
            ])),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_a_menu_item, {
            key: "2",
            onClick: _ctx.cleanAll
          }, {
            default: _withCtx(() => _cache[14] || (_cache[14] = [
              _createTextVNode("필터 전체 초기화")
            ])),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_a_menu_item, {
            key: "3",
            onClick: _ctx.logout
          }, {
            default: _withCtx(() => _cache[15] || (_cache[15] = [
              _createTextVNode("로그아웃")
            ])),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    icon: _withCtx(() => [
      (_ctx.userStore.profileImageUrl)
        ? (_openBlock(), _createBlock(_component_a_avatar, {
            key: 0,
            src: _ctx.userStore.profileImageUrl
          }, null, 8, ["src"]))
        : (_openBlock(), _createBlock(_component_user_outlined, { key: 1 }))
    ]),
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.userStore.userName || '???') + " ", 1),
      _createVNode(_component_a_modal, {
        visible: _ctx.userVisible,
        "onUpdate:visible": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.userVisible) = $event)),
        title: "사용자 정보",
        width: "900px"
      }, {
        footer: _withCtx(() => [
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: _ctx.updateUser,
            loading: _ctx.loading,
            disabled: _ctx.userStore.id == null
          }, {
            default: _withCtx(() => _cache[19] || (_cache[19] = [
              _createTextVNode(" 수정 ")
            ])),
            _: 1
          }, 8, ["onClick", "loading", "disabled"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            class: "user__modal",
            model: _ctx.userStore,
            rules: { bindUserId: [{ required: true }] }
          }, {
            default: _withCtx(() => [
              (_ctx.userStore.loginType == 'AWS_COGNITO')
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                    _createVNode(_component_a_form_item, { label: "Cognito 사용자명" }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.userStore.cognitoUsername), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_form_item, {
                      name: "bindUserId",
                      label: "사용 유저 아이디",
                      extra: "`서비스에서 사용되는 유저ID. 어드민 계정과 연동합니다.\n                        유저의 아이디는 현재 ID가 갖는 권한을 부여받습니다.\n                        연동하지 않을시 어드민에서 일부 서비스는 작동하지 않을수 있습니다.\n                        (변경시에 이전 ID는 사용자로 강등됩니다)`"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_auto_complete_input, {
                          value: _ctx.userStore.bindUserId,
                          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userStore.bindUserId) = $event)),
                          uri: `/api/user`
                        }, {
                          ext: _withCtx(() => [
                            _createVNode(_component_a_button, {
                              onClick: _ctx.updateCognitoUser,
                              loading: _ctx.loading,
                              disabled: 
                                        _ctx.userStore.bindUserId == null ||
                                        _ctx.userStore.bindUserId == ''
                                    
                            }, {
                              default: _withCtx(() => _cache[16] || (_cache[16] = [
                                _createTextVNode("유저 변경")
                              ])),
                              _: 1
                            }, 8, ["onClick", "loading", "disabled"])
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_a_form_item, { label: "프로필 이미지" }, {
                default: _withCtx(() => [
                  _createVNode(_component_image_input, {
                    value: _ctx.userStore.profileImageUrl,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userStore.profileImageUrl) = $event)),
                    path: `/admin/profile/${_ctx.userStore.id}`
                  }, null, 8, ["value", "path"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, { label: "닉네임" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.userStore.userName,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.userStore.userName) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, { label: "프로필 닉네임" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.userStore.profileName,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.userStore.profileName) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, { label: "Role" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.userStore.role,
                    "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.userStore.role) = $event)),
                    disabled: ""
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              (_ctx.userStore.loginType == 'KAKAO')
                ? (_openBlock(), _createBlock(_component_a_form_item, {
                    key: 1,
                    label: "경력",
                    colon: false
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form, {
                            layout: "inline",
                            model: _ctx.careerForm,
                            rules: _ctx.rules,
                            onFinish: _cache[7] || (_cache[7] = 
                                (values) =>
                                    _ctx.addProfile(_ctx.careerForm, 'CAREER', values)
                            )
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, {
                                label: "내용",
                                name: "description"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: _ctx.careerForm.description,
                                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.careerForm.description) = $event)),
                                    style: {"width":"300px !important"}
                                  }, null, 8, ["value"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_form_item, {
                                label: "취득일",
                                name: "registeredAt"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_date_picker, {
                                    "show-time": "",
                                    value: _ctx.careerForm.registeredAt,
                                    "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.careerForm.registeredAt) = $event))
                                  }, null, 8, ["value"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_form_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_button, {
                                    type: "primary",
                                    "html-type": "submit"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_plus_outlined)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["model", "rules"])
                        ]),
                        _: 1
                      }),
                      _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userStore.profiles.filter((it) => it.type == 'CAREER'), (profile, index) => {
                        return (_openBlock(), _createBlock(_component_a_row, {
                          key: index,
                          style: {"margin-bottom":"10px"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form, {
                              layout: "inline",
                              model: profile,
                              rules: _ctx.rules
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, {
                                  label: "내용",
                                  name: "description"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input, {
                                      value: profile.description,
                                      "onUpdate:value": ($event: any) => ((profile.description) = $event),
                                      style: {"width":"300px !important"}
                                    }, null, 8, ["value", "onUpdate:value"])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_a_form_item, {
                                  label: "취득일",
                                  name: "registeredAt"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_date_picker, {
                                      value: profile.registeredAt,
                                      "onUpdate:value": ($event: any) => ((profile.registeredAt) = $event),
                                      "show-time": ""
                                    }, null, 8, ["value", "onUpdate:value"])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_a_form_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_button, {
                                      type: "danger",
                                      onClick: ($event: any) => (_ctx.deleteProfile(profile))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_delete_outlined)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"])
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["model", "rules"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_a_divider),
              _createVNode(_component_a_form_item, { label: "학력" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form, {
                        layout: "inline",
                        model: _ctx.degreeForm,
                        rules: _ctx.rules,
                        onFinish: _cache[10] || (_cache[10] = (values) => _ctx.addProfile(_ctx.degreeForm, 'DEGREE', values))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            label: "내용",
                            name: "description"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.degreeForm.description,
                                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.degreeForm.description) = $event)),
                                style: {"width":"300px !important"}
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_form_item, {
                            label: "취득일",
                            name: "registeredAt"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_date_picker, {
                                "show-time": "",
                                value: _ctx.degreeForm.registeredAt,
                                "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.degreeForm.registeredAt) = $event))
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_form_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_button, {
                                type: "primary",
                                "html-type": "submit"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_plus_outlined)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["model", "rules"])
                    ]),
                    _: 1
                  }),
                  _cache[18] || (_cache[18] = _createElementVNode("br", null, null, -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userStore.profiles.filter((it) => it.type == 'DEGREE'), (profile) => {
                    return (_openBlock(), _createBlock(_component_a_row, {
                      key: profile.id,
                      style: {"margin-bottom":"10px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form, {
                          layout: "inline",
                          model: profile,
                          rules: _ctx.rules
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, {
                              label: "내용",
                              name: "description"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input, {
                                  value: profile.description,
                                  "onUpdate:value": ($event: any) => ((profile.description) = $event),
                                  style: {"width":"300px !important"}
                                }, null, 8, ["value", "onUpdate:value"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_form_item, {
                              label: "취득일",
                              name: "registeredAt"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_date_picker, {
                                  value: profile.registeredAt,
                                  "onUpdate:value": ($event: any) => ((profile.registeredAt) = $event),
                                  "show-time": ""
                                }, null, 8, ["value", "onUpdate:value"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_form_item, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_button, {
                                  type: "danger",
                                  onClick: ($event: any) => (_ctx.deleteProfile(profile))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_delete_outlined)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["model", "rules"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "SNS 및 블로그 주소",
                name: "url"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.userStore.contactLink,
                    "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.userStore.contactLink) = $event)),
                    style: {"width":"300px !important"}
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"])
        ]),
        _: 1
      }, 8, ["visible"])
    ]),
    _: 1
  }, 8, ["id"]))
}