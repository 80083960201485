<template>
    <ResourceTable
        title="포인트샵 주문"
        uri="/api/admin/point-shop/orders"
        :filter-spec="filterSpec"
        :scroll="{ x: 2520, y: 650 }"
        :columns="columns"
        :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
        }"
        resource-name="pointShopOrders"
        exportable
        load-on-mount
    >
        <template #actions="{ uri, search }">
            <a-button
                @click="
                    () => updateOrderStatus('DELIVERY_PREPARE', uri, search)
                "
            >
                발송준비
            </a-button>
            <a-button
                @click="
                    () => updateOrderStatus('DELIVERY_COMPLETE', uri, search)
                "
            >
                발송완료
            </a-button>
            <a-button
                @click="() => updateOrderStatus('DELIVERY_RESEND', uri, search)"
            >
                재전송
            </a-button>
        </template>
        <template #detail="{ record }">
            <a-descriptions
                title="리뷰 상세"
                :bordered="true"
                layout="vertical"
            >
                <a-descriptions-item label="리뷰 ID">
                    {{ record.id }}
                </a-descriptions-item>
            </a-descriptions>
        </template>
    </ResourceTable>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { stringify } from 'qs'
import TableSupport from '@/views/tableSupport'
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent, Ref, ref } from 'vue'
import { useEnumTypeStore } from '@/store/enumType'
import { InputComponents } from '@/components/InputMapper.vue'
import { formatLocalDateTime } from '@/util/formmater'

export default defineComponent({
    name: 'PointShopOrders',

    mixins: [TableSupport],

    components: {
        ResourceTable,
    },

    setup() {
        const loading = ref(false)
        const selectedRowKeys: Ref<Array<number>> = ref([])
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: '128px',
                align: 'left',
                fixed: 'left',
            },
            {
                title: '사용자 ID',
                dataIndex: 'userId',
                width: '96px',
            },
            {
                title: '이름',
                dataIndex: 'userName',
                width: '128px',
                align: 'center',
            },
            {
                title: '전화번호',
                dataIndex: 'userPhoneNumber',
                width: '192px',
                align: 'center',
            },
            {
                title: '주문상태',
                dataIndex: 'status',
                width: '96px',
                align: 'center',
                customRender: (record: any) => {
                    const status = record.value
                    const result = (status: string) => {
                        switch (status) {
                            case 'DELIVERY_PREPARE':
                                return '발송준비'
                            case 'DELIVERY_COMPLETE':
                                return '발송완료'
                            case 'DELIVERY_RESEND':
                                return '재전송'
                            default:
                                return '-'
                        }
                    }
                    return result(status)
                },
            },
            {
                title: '상품 ID',
                dataIndex: 'pointShopProductId',
                width: '96px',
                align: 'center',
            },
            {
                title: '상품명',
                dataIndex: 'pointShopProductName',
                width: '256px',
            },
            {
                title: '포인트',
                dataIndex: 'usedPointAmount',
                width: '96px',
                align: 'center',
            },
            {
                title: '주문명',
                dataIndex: 'orderName',
            },
            {
                title: '주문정보',
                dataIndex: 'pointShopOrderId',
                width: '256px',
            },
            {
                title: '구매처 이름',
                dataIndex: 'giftShopName',
                width: '96px',
                align: 'center',
            },
            {
                title: '발송준비',
                dataIndex: 'deliveryPrepareAt',
                width: '192px',
                customRender: formatLocalDateTime,
            },
            {
                title: '발송완료',
                dataIndex: 'deliveryCompleteAt',
                width: '192px',
                customRender: formatLocalDateTime,
            },
            {
                title: '재전송',
                dataIndex: 'deliveryResendAt',
                width: '192px',
                customRender: formatLocalDateTime,
            },
        ]

        const filterSpec = ref({
            id: {
                label: 'ID',
                value: null,
                component: 'AInput',
            },
            status: {
                label: '상태',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'point-shop-product-order-status',
            },
            deliveryPrepareAt: {
                label: '발송준비(주문일)',
                value: null,
                component: 'ARangePicker',
            },
            deliveryCompleteAt: {
                label: '발송완료',
                value: null,
                component: 'ARangePicker',
            },
            deliveryResendAt: {
                label: '재전송',
                value: null,
                component: 'ARangePicker',
            },
        })

        const rowSelection = ref({
            selectedRowKeys: [],
            selectedRows: [],
            onChange: (selectedRowKeys: any, selectedRows: any) => {
                rowSelection.value.selectedRowKeys = selectedRowKeys
                rowSelection.value.selectedRows = selectedRows
            },
        })

        return {
            loading,
            selectedRowKeys,
            columns,
            filterSpec,
            rowSelection,
        }
    },

    methods: {
        onSelectChange(selectedRowKeys: Array<number>) {
            this.selectedRowKeys = selectedRowKeys
        },
        updateOrderStatus(status: string, uri: string, search: () => void) {
            const params: Record<string, any> = {
                ids: this.selectedRowKeys,
                status: status,
            }
            const callUri = `${uri}/status`
            const queryParams = stringify(params, { indices: false })
            this.patchStatus(queryParams, callUri, search)
        },
        async patchStatus(param: string, uri: string, search: () => void) {
            if (this.selectedRowKeys.length > 0 && this.loading === false) {
                this.loading = true
                try {
                    await adminApi<ApiResponse<void>>(`${uri}?${param}`, {
                        method: 'PATCH',
                    })
                } catch (err) {
                    console.error(err)
                    throw err
                } finally {
                    setTimeout(() => {
                        this.loading = false
                        this.selectedRowKeys = []
                        search()
                    }, 1000)
                }
            }
        },
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums(['point-shop-product-order-status'])
    },
})
</script>
