import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import ResourceTable from '@/components/ResourceTable.vue'
import {
    formatEnum,
    formatLocalDate,
    formatLocalDateTime,
    formatWeekOfDay,
} from '@/util/formmater'
import { onMounted, ref } from 'vue'
import {
    FormSpecification,
    InputComponents,
} from '@/components/InputMapper.vue'
import { useEnumTypeStore } from '@/store/enumType'
import { Tag } from 'ant-design-vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UserDietProgram',
  setup(__props) {

const warningCell = (record: Record<any, any>) => {
    if (record.findFactorDate) {
        return
    }
    if (record.findFactorEnable) {
        return {
            style: {
                backgroundColor: '#FFEFF2',
            },
        }
    }
}

const columns = [
    {
        title: '유저ID',
        dataIndex: 'userId',
        width: 80,
        fixed: 'left',
        customCell: warningCell,
    },
    {
        title: '이름',
        dataIndex: 'userName',
        customCell: warningCell,
    },
    {
        title: '시작일',
        dataIndex: 'startDate',
        customRender: formatLocalDate,
        customCell: warningCell,
    },
    {
        title: '종료일',
        dataIndex: 'endDate',
        customRender: formatLocalDate,
        customCell: warningCell,
    },
    {
        title: '피드백 요일',
        dataIndex: 'findFactorDate',
        customRender: formatWeekOfDay,
        customCell: warningCell,
    },
    {
        title: '수정자',
        dataIndex: 'updatedBy',
        customCell: warningCell,
    },
    {
        title: '수정일시',
        dataIndex: 'updatedAt',
        sorter: true,
        customRender: formatLocalDateTime,
        customCell: warningCell,
    },
    {
        title: '피드백 단계',
        dataIndex: 'formeDietFeedbackLevel',
        customRender: formatEnum('forme-diet-feedback-level'),
        customCell: warningCell,
    },
    {
        title: '피드백 제공 날짜',
        dataIndex: 'feedbackDate',
        customCell: warningCell,
    },
    {
        title: '상태',
        dataIndex: 'completed',
        customRender: (col: any) =>
            col.value ? (
                <Tag color="blue">완료</Tag>
            ) : (
                <Tag color="red">미완료</Tag>
            ),
        align: 'center',
        customCell: warningCell,
    },
    {
        title: '만족도설문',
        dataIndex: 'surveyStep',
        customRender: (col: any) => {
            if (col.value == undefined || col.value == '') {
                return ''
            } else if (col.value == '범인 찾기전') {
                return <Tag color="grey">{col.value}</Tag>
            } else if (col.value.includes('미완료') == 1) {
                return <Tag color="red">{col.value}</Tag>
            } else {
                return <Tag color="blue">{col.value}</Tag>
            }
        },
        align: 'center',
        width: 100,
        customCell: warningCell,
    },
    {
        title: '안읽은 Q&A 수',
        dataIndex: 'qnaUnreadCount',
        align: 'center',
        width: 80,
        customCell: warningCell,
    },
    {
        title: '사용자 안읽은 메세지수',
        dataIndex: 'userUnreadCount',
        align: 'center',
        width: 80,
        customCell: warningCell,
    },
]

const filterSpec = ref<FormSpecification>({
    userId: {
        label: '사용자 ID',
    },
    userName: {
        label: '사용자 이름',
    },
    updatedBy: {
        label: '수정자',
    },
    formeDietFeedbackLevel: {
        label: '피드백 단계',
        component: InputComponents.EnumSelect,
        typeName: 'forme-diet-feedback-level',
    },
    findFactorDay: {
        label: '피드백 요일',
    },
    completed: {
        label: '상태',
        component: InputComponents.BooleanInput,
        trueText: '완료',
        falseText: '미완료',
    },
    feedbackDate: {
        label: '피드백 제공 날짜',
        component: InputComponents.ADatePicker,
    },
    findFactorReady: {
        label: '범인 찾기 대기',
        component: InputComponents.BooleanInput,
        toggle: true,
    },
})

const enumTypeStore = useEnumTypeStore()

onMounted(() => {
    enumTypeStore.dispatchEnums(['forme-diet-feedback-level'])
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ResourceTable, {
    title: "포미 다이어트 유저 관리",
    "primary-key": "userId",
    "resource-name": "formeDiet",
    uri: "/api/forme/diet/membership",
    "filter-spec": filterSpec.value,
    columns: columns,
    "load-on-mount": "",
    "has-edit-page": ""
  }, null, 8, ["filter-spec"]))
}
}

})