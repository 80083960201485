import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_content_image_input = _resolveComponent("content-image-input")!
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_enum_select = _resolveComponent("enum-select")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    width: "75%",
    open: _ctx.isOpen,
    "onUpdate:open": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isOpen) = $event)),
    title: "",
    onOk: _ctx.clickOK,
    onCancel: _ctx.clickCancel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_descriptions, {
        title: "슬라이드 이미지 설정",
        layout: "horizontal",
        size: "small",
        bordered: true,
        column: 1
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_descriptions_item, { label: "이미지 추가" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_descriptions, {
                layout: "vertical",
                size: "small",
                column: 1,
                bordered: true
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_descriptions_item, { label: "이미지" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_content_image_input, {
                        value: _ctx.currentImage,
                        name: "resourceUrl",
                        path: "/event-image-slider",
                        style: {"display":"flex"},
                        "onUpdate:value": _ctx.uploadImage
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_descriptions_item, { label: "액션 타입" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_enum_select, {
                        value: _ctx.value.actionType,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.actionType) = $event)),
                        typeName: "event-action-type"
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_descriptions_item, { label: "액션 파라미터" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_textarea, {
                        value: _ctx.value.actionParameter,
                        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.actionParameter) = $event))
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_descriptions_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, { onClick: _ctx.addImage }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode("이미지 추가")
                        ])),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_descriptions_item, { label: "이미지 리스트" }, {
            default: _withCtx(() => [
              _createVNode(_component_draggable, {
                list: _ctx.value.slideImages,
                "item-key": "value",
                class: "content-container"
              }, {
                item: _withCtx(({ element, index }) => [
                  _createVNode(_component_a_col, {
                    span: 7,
                    class: "content-box"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        danger: "",
                        class: "btn-remove-content",
                        onClick: ($event: any) => (_ctx.remove(index))
                      }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode(" 삭제 ")
                        ])),
                        _: 2
                      }, 1032, ["onClick"]),
                      _createElementVNode("img", {
                        alt: "slide-thumbnail",
                        class: "slide-thumbnail",
                        src: element.imageUrl
                      }, null, 8, _hoisted_1),
                      _createVNode(_component_a_row, { class: "description-box" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, {
                            span: 24,
                            class: "desc-title"
                          }, {
                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                              _createTextVNode(" ACTION TYPE ")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_a_col, {
                            span: 24,
                            class: "desc-value"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(element.actionType), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_a_row, { class: "description-box" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, {
                            span: 24,
                            class: "desc-title"
                          }, {
                            default: _withCtx(() => _cache[6] || (_cache[6] = [
                              _createTextVNode(" ACTION PARAMETER ")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_a_col, {
                            span: 24,
                            class: "desc-value"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(element.actionParameter), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["list"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["open", "onOk", "onCancel"]))
}