<script setup lang="ts">
import ResourceTable from '@/components/ResourceTable.vue'
import { defineProps, onMounted, ref } from 'vue'
import {
    formatBoolean,
    formatEnum,
    formatLocalDateTime,
} from '@/util/formmater'
import { required } from '@/util/input-validation'
import { InputComponents } from '@/components/InputMapper.vue'
import router from '@/router'
import { adminApi } from '@/fetchTemplate'
import { message } from 'ant-design-vue'

const props = defineProps({
    userId: {
        type: String,
        required: true,
    },
})

const filterSpec = ref({
    userId: {
        label: '사용자 ID',
        value: props.userId,
    },
})

const deviceRules = {
    startAt: [required],
    endAt: [required],
    deviceType: [required],
}

const deviceSpec = ref({
    startAt: {
        label: '시작일시',
        value: null,
        component: InputComponents.ADatetimePicker,
    },
    endAt: {
        label: '종료일시',
        value: null,
        component: InputComponents.ADatetimePicker,
    },
    deviceType: {
        label: '센서 타입',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'cgm-device-type',
    },
})

const deviceColumn = [
    {
        title: 'ID',
        dataIndex: 'tsid',
        width: 120,
        fixed: 'left',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
        },
        sorter: true,
    },
    {
        title: '센서 타입',
        dataIndex: 'deviceType',
        customRender: formatEnum('cgm-device-type'),
        width: 50,
    },
    {
        title: '자동 등록',
        dataIndex: 'autoRegister',
        customRender: formatBoolean,
        width: 60,
    },
    {
        title: '무료 체험',
        dataIndex: 'freeTrial',
        customRender: formatBoolean,
        width: 60,
    },
    {
        title: '센서 상태',
        dataIndex: 'sensorStatus',
        width: 60,
    },
    {
        title: '시작일시',
        dataIndex: 'startAt',
        customRender: formatLocalDateTime,
        width: 100,
    },
    {
        title: '종료일시',
        dataIndex: 'endAt',
        customRender: formatLocalDateTime,
        width: 100,
    },
]

const visible = ref(true)

const toggleVisible = () => {
    visible.value = !visible.value
    router.back()
}

const loading = ref(false)
const forceRegisterVcgm = async (search: () => void) => {
    loading.value = true
    try {
        await adminApi(`/api/cgm/force-register-vcgm/${props.userId}`, {
            method: 'POST',
        })
        await search()
    } catch (e) {
        console.error(e)
        message.error('VCGM 등록에 실패했습니다.')
    } finally {
        loading.value = false
    }
}

const vcgmEnable = ref(false)
const fetchVcgmStatus = async () => {
    const response = await adminApi(`/api/cgm/vcgm-status/${props.userId}`)
    vcgmEnable.value = !response.result
}

onMounted(() => {
    fetchVcgmStatus()
})
</script>

<template>
    <a-drawer :visible="true" @close="toggleVisible" width="900px">
        <ResourceTable
            ref="cgmResourceTable"
            resource-name="cgmDevice"
            title="CGM 센서"
            uri="/api/ai-coaching-schedule/cgm-device"
            :columns="deviceColumn"
            :filter-spec="filterSpec"
            :update-spec="deviceSpec"
            :update-rules="deviceRules"
            deletable
            load-on-mount
            primary-key="tsid"
        >
            <template #actions="{ search }">
                <a-button
                    type="primary"
                    @click="forceRegisterVcgm(search)"
                    :loading="loading"
                    :disabled="vcgmEnable"
                >
                    VCGM 강제 등록
                </a-button>
            </template>
        </ResourceTable>
    </a-drawer>
</template>

<style scoped></style>
